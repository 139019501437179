import React from "react"
import loadable from "@loadable/component"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const PostsList = loadable(() => import("../components/PostsList"))

const CategoryTemplate = props => {
  const {
    data: {
      categories: { nodes: posts },
    },
  } = props

  const {
    pageContext: { numPages, currentPage, category },
  } = props

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? `/${category}`
      : `/${category}/${(currentPage - 1).toString()}`
  const nextPage = `/${category}/${(currentPage + 1).toString()}`

  return (
    <Layout>
      <SEO
        title={`Kategoria: ${
          category.charAt(0).toUpperCase() + category.slice(1)
        }`}
        description={`Wpisy w kategorii ${
          category.charAt(0).toUpperCase() + category.slice(1)
        }`}
      />
      <PostsList
        posts={posts}
        header={`Wpisy w kategorii ${
          category.charAt(0).toUpperCase() + category.slice(1)
        }`}
      />
      <div className="pagination">
        {!isFirst && (
          <Link to={prevPage} rel="prev" className="pagination-btn">
            <svg
              fill="#000000"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              ></path>
            </svg>
            <span className="pagination-prev">Poprzednie</span>
          </Link>
        )}
        {!isLast && (
          <Link to={nextPage} rel="next" className="pagination-btn">
            <span className="pagination-mext">Następne</span>
            <svg
              fill="#000000"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              ></path>
            </svg>
          </Link>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetCategories($category: String, $skip: Int!, $limit: Int!) {
    categories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          category
          post_id
          slug
          preview
          title
        }
        id
      }
    }
  }
`

export default CategoryTemplate
